/* website: 2515-cornerbrooknissan
 * created at 2023-06-15 10:43 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/nissan.scss";
@import "../utils/icons.scss";
.widget-sr{
  &.dealer__corner-brook-nissan{
    .financing-form-inline-gender{
      [type=radio]+label{
        color: #000000 !important;
      }
    }
    .showroom-vdp-used{
      form{
        .sr-title-2,
        .visit-step{
            color:#000000;
        }
      }
    }
  }
}